import './_vendor';

const accsItems = document.querySelectorAll('.faq-list__item');
const mobNav = document.querySelector('.header-nav');
const panelMenu = document.querySelector('.menu-burger');
const body = document.body;
const winModal = document.querySelector('#win-modal');
const winButton = document.querySelector('.open-button')
const closeModalButtons = document.querySelectorAll('.modal-close');
const filterButton = document.querySelector('.panel-filter__btn');
const filterModal = document.querySelector('.panel-filter');
const filterAcceptBtn = document.querySelector('.panel-filter__accept');

const menuHandler = () => {
  panelMenu.classList.toggle('menu-burger--active');

  if (panelMenu.classList.contains('menu-burger--active')) {
    mobNav.classList.add('header-nav--active')
    document.documentElement.style.overflow = 'hidden';
    body.style.overflow = 'hidden'
  } else {
    mobNav.classList.remove('header-nav--active')
    document.documentElement.style.overflow = '';
    body.style.overflow = ''
  }
}

function hideModal() {
  body.classList.remove('locked')
  document.documentElement.classList.remove('locked')
}

function showModal() {
  body.classList.add('locked');
  document.documentElement.classList.add('locked');
}

function closeModalByOuterClick (modal) {
  const modalContainer = modal.querySelector('.modal-container');

  modalContainer.addEventListener('click', (e) => {
    if (e.target === modalContainer) {
      hideModal();
      modal.classList.remove('modal--active');
    }
  })
}

if (winButton) {
  winButton.addEventListener('click', () => {
    winModal.classList.add('modal--active');

    closeModalByOuterClick(winModal);
    showModal();
  })
  closeModalButtons.forEach(btn =>{
    document.querySelectorAll('.modal').forEach(modal => {
      btn.addEventListener('click', () => {
        hideModal();
        modal.classList.remove('modal--active')
      })
    })
  })
}

if (mobNav) {
  panelMenu.addEventListener('click', menuHandler);
}

if (accsItems) {
  accsItems.forEach(item => {
    item.addEventListener('click', function() {
      item.classList.toggle('faq-list__item--active')
      const hiddenBlock = this.querySelector('.faq-list__hidden')
      const hiddenContent = this.querySelector('.faq-list__content')

      if (this.classList.contains('faq-list__item--active')) {
        hiddenBlock.style.maxHeight = `${hiddenContent.scrollHeight}px`;
      }
      if (!this.classList.contains('faq-list__item--active')) {
        hiddenBlock.style.maxHeight = 0;
      }
    })
  })

}

if (filterModal) {
  filterButton.addEventListener('click', () => {
    filterButton.classList.toggle('panel-filter__btn--active');

    if (filterButton.classList.contains('panel-filter__btn--active')) {
      filterModal.classList.add('panel-filter--show')
      body.classList.add('body--active')
    }
    else {
      filterModal.classList.remove('panel-filter--show')
    }
  })

  filterAcceptBtn.addEventListener('click', () => {
    filterModal.classList.remove('panel-filter--show')
    filterButton.classList.remove('panel-filter__btn--active');
    body.classList.remove('body--active')
  })

}
